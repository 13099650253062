import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen14 } from '../../../components/images/support/certifications/Screen14.generated';
import { Screen15 } from '../../../components/images/support/certifications/Screen15.generated';
import { Screen16 } from '../../../components/images/support/certifications/Screen16.generated';
import { Screen17 } from '../../../components/images/support/certifications/Screen17.generated';
import { Screen18 } from '../../../components/images/support/certifications/Screen18.generated';
import { Screen19 } from '../../../components/images/support/certifications/Screen19.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "assigning-certifications-to-an-employee-️"
    }}>{`Assigning Certifications to an Employee 🧑‍🍳👨🏾‍💼👷‍♀️`}</h1>
    <p>{`In this example we will assign a Certification as a requirement to a specific Employee.`}</p>
    <p>{`Make sure you have created Certifications before you begin this step. You can learn how to do that `}<a parentName="p" {...{
        "href": "/support/certifications/adding-certifications"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "lets-assign-a-certification"
    }}>{`Let's assign a Certification`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu.`}</li>
    </ol>

    <Screen14 mdxType="Screen14" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the user you would like to assign a Certification to.`}</li>
    </ol>

    <Screen15 mdxType="Screen15" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the "Requirements" button in the Certifications section.`}</li>
    </ol>

    <Screen16 mdxType="Screen16" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click "Add Requirement" for the Certification you would like to add as a requirement.`}</li>
    </ol>

    <Screen17 mdxType="Screen17" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`We can now see the Certification has been added as a requirement.`}</li>
    </ol>

    <Screen18 mdxType="Screen18" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`If we navigate to the Certifications menu we can see that the User now has a requirement for that Certification.`}</li>
    </ol>

    <Screen19 mdxType="Screen19" />
    <p>{`Great Job you have assigned your Certification 🎉`}</p>
    <p>{`After an employee uploads their Certifications they will need to be approved.`}</p>

    <SupportFooter linkTo="/support/certifications/approving-certifications" linkText="Learn how to approve Certifications" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      